<template>
  <div class="wealth">
    <div class="row-wealth"
      v-for="(item, index) in list.children"
      :key="index">
      <h1 class="title">{{ item.title }}</h1>
      <div class="row-child" v-for="(list, idx) in item.children"
        :key="idx">
        <h3 class="small-title">{{ list.title }}</h3>
        <div class="content" v-html="list.content"></div>
      </div>
    </div>
  </div>
</template>
<script>
import wealthJson from './utils/wealth.json';

export default {
  name: 'IntroWealth',
  data() {
    return {
      list: {},
    };
  },
  computed: {
    id() {
      return this.$route.query.id;
    },
  },
  mounted() {
    let { id } = this;
    let data = wealthJson[id];
    if (data) {
      this.list = data;
      document.title = data.title;
    }
  },
};
</script>
<style lang="scss" scoped>
  .wealth {
    width: 100%;
    min-height: 100%;
    background-color: #f9f9f9;
    padding-top: 35px;
    font-family: PingFangSC-Medium, PingFang SC;
  }
  .row-wealth {
    margin: 0 24px 0 28px;
    padding-bottom: 90px;
    /deep/ img {
      max-width: 100%;
    }
    > .title {
      font-size: 32px;
      font-weight: 500;
      color: #333333;
      line-height: 45px;
    }
    .small-title {
      margin-top: 20px;
      font-size: 28px;
      font-weight: 500;
      color: #333333;
      line-height: 50px;
    }
    .content {
      margin-top: 20px;
      font-size: 28px;
      font-weight: 400;
      color: #333333;
      line-height: 50px;
      /deep/ p {
        margin-bottom: 14px;
      }
    }
  }
</style>
